.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #1B1B1E;
  width: 100vw;
  height: 100vh;
  font-family: "Roboto Regular", serif;
}

@font-face {
  font-family: 'Roboto Regular';
  src: url('Roboto-Regular.ttf') format("truetype");
}

.TitleContainer {
  margin: 21vh auto auto;
  display: flex;
}

.Title {
  font-size: 100px;
  color: white;
}

.SubtitleContainer {
  display: flex;
  margin: 16vh auto auto;
}

.Subtitle {
  color: white;
  font-size: 30px;
}

.EmailContainer {
  display: flex;
  margin: auto auto 3vh;
}

.Email {
  color: white;
  font-size: 30px;
}

.CopyrightContainer {
  display: flex;
  margin: auto auto 2vh;
}

.Copyright {
  color: white;
  font-size: 20px;
}

.LowerContainer {
  display: flex;
  flex-direction: column;
  margin: 34vh auto auto;
}

@media (max-width: 500px) {

  .App {
    padding-bottom: 2vh;
  }

  .CopyrightContainer {
    margin: auto;
  }

  .Title {
    font-size: 70px;
  }

  .SubtitleContainer {
    padding-right: 10%;
    padding-left: 10%;
  }

  .Subtitle {
    font-size: 21px;
  }

  .Email {
    font-size: 21px;
  }

  .Copyright {
    font-size: 14px;
  }

  .LowerContainer {
    margin-bottom: 4vh;
  }


}